/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Information whether a block was signed by the validator.
 * @export
 * @interface ValidatorSignedBlock
 */
export interface ValidatorSignedBlock {
    /**
     * The block height.
     * @type {number}
     * @memberof ValidatorSignedBlock
     */
    height: number;
    /**
     * Whether the validator signed the block.
     * @type {boolean}
     * @memberof ValidatorSignedBlock
     */
    signed: boolean;
}

export function ValidatorSignedBlockFromJSON(json: any): ValidatorSignedBlock {
    return ValidatorSignedBlockFromJSONTyped(json, false);
}

export function ValidatorSignedBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorSignedBlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'height': json['height'],
        'signed': json['signed'],
    };
}

export function ValidatorSignedBlockToJSON(value?: ValidatorSignedBlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'height': value.height,
        'signed': value.signed,
    };
}


