/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvmTokenSwap
 */
export interface EvmTokenSwap {
    /**
     * The Oasis address of the swap pair contract.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    pair_address: string;
    /**
     * The Ethereum-compatible address of the swap pair contract.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    pair_address_eth?: string;
    /**
     * The Oasis address of the swap factory contract.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    factory_address?: string;
    /**
     * The Ethereum-compatible address of the swap factory contract.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    factory_address_eth?: string;
    /**
     * The Oasis address of the first token in this swap.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    token0_address?: string;
    /**
     * The Ethereum-compatible address of the first token in this swap
     * @type {string}
     * @memberof EvmTokenSwap
     */
    token0_address_eth?: string;
    /**
     * The Oasis address of the second token in this swap.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    token1_address?: string;
    /**
     * The Ethereum-compatible address of the second token in this swap.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    token1_address_eth?: string;
    /**
     * The round when this swap pair was created.
     * @type {number}
     * @memberof EvmTokenSwap
     */
    create_round?: number;
    /**
     * The swap's liquidity pool of the first token, in that token's base units.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    reserve0?: string;
    /**
     * The swap's liquidity pool of the second token, in that token's base units.
     * @type {string}
     * @memberof EvmTokenSwap
     */
    reserve1?: string;
    /**
     * The round when this swap pair last updated its reserves.
     * @type {number}
     * @memberof EvmTokenSwap
     */
    last_sync_round?: number;
}

export function EvmTokenSwapFromJSON(json: any): EvmTokenSwap {
    return EvmTokenSwapFromJSONTyped(json, false);
}

export function EvmTokenSwapFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvmTokenSwap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pair_address': json['pair_address'],
        'pair_address_eth': !exists(json, 'pair_address_eth') ? undefined : json['pair_address_eth'],
        'factory_address': !exists(json, 'factory_address') ? undefined : json['factory_address'],
        'factory_address_eth': !exists(json, 'factory_address_eth') ? undefined : json['factory_address_eth'],
        'token0_address': !exists(json, 'token0_address') ? undefined : json['token0_address'],
        'token0_address_eth': !exists(json, 'token0_address_eth') ? undefined : json['token0_address_eth'],
        'token1_address': !exists(json, 'token1_address') ? undefined : json['token1_address'],
        'token1_address_eth': !exists(json, 'token1_address_eth') ? undefined : json['token1_address_eth'],
        'create_round': !exists(json, 'create_round') ? undefined : json['create_round'],
        'reserve0': !exists(json, 'reserve0') ? undefined : json['reserve0'],
        'reserve1': !exists(json, 'reserve1') ? undefined : json['reserve1'],
        'last_sync_round': !exists(json, 'last_sync_round') ? undefined : json['last_sync_round'],
    };
}

export function EvmTokenSwapToJSON(value?: EvmTokenSwap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pair_address': value.pair_address,
        'pair_address_eth': value.pair_address_eth,
        'factory_address': value.factory_address,
        'factory_address_eth': value.factory_address_eth,
        'token0_address': value.token0_address,
        'token0_address_eth': value.token0_address_eth,
        'token1_address': value.token1_address,
        'token1_address_eth': value.token1_address_eth,
        'create_round': value.create_round,
        'reserve0': value.reserve0,
        'reserve1': value.reserve1,
        'last_sync_round': value.last_sync_round,
    };
}


