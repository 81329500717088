/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvmTokenType,
    EvmTokenTypeFromJSON,
    EvmTokenTypeFromJSONTyped,
    EvmTokenTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface EvmRefToken
 */
export interface EvmRefToken {
    /**
     * The heuristically determined interface that the token contract implements.
     * A less specialized variant of the token might be detected; for example, an
     * ERC-1363 token might be labeled as ERC-20 here. If the type cannot be
     * detected or is not supported, this field will be null/absent.
     * @type {EvmTokenType}
     * @memberof EvmRefToken
     */
    type: EvmTokenType;
    /**
     * Name of the token, as provided by token contract's `name()` method.
     * @type {string}
     * @memberof EvmRefToken
     */
    name?: string;
    /**
     * Symbol of the token, as provided by token contract's `symbol()` method.
     * @type {string}
     * @memberof EvmRefToken
     */
    symbol?: string;
    /**
     * The number of least significant digits in base units that should be displayed as
     * decimals when displaying tokens. `tokens = base_units / (10**decimals)`.
     * Affects display only. Often equals 18, to match ETH.
     * @type {number}
     * @memberof EvmRefToken
     */
    decimals?: number;
}

export function EvmRefTokenFromJSON(json: any): EvmRefToken {
    return EvmRefTokenFromJSONTyped(json, false);
}

export function EvmRefTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvmRefToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': EvmTokenTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'decimals': !exists(json, 'decimals') ? undefined : json['decimals'],
    };
}

export function EvmRefTokenToJSON(value?: EvmRefToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': EvmTokenTypeToJSON(value.type),
        'name': value.name,
        'symbol': value.symbol,
        'decimals': value.decimals,
    };
}


