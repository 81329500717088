/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountToJSON,
    AccountList,
    AccountListFromJSON,
    AccountListToJSON,
    ActiveAccountsList,
    ActiveAccountsListFromJSON,
    ActiveAccountsListToJSON,
    Block,
    BlockFromJSON,
    BlockToJSON,
    BlockList,
    BlockListFromJSON,
    BlockListToJSON,
    ConsensusEventList,
    ConsensusEventListFromJSON,
    ConsensusEventListToJSON,
    ConsensusEventType,
    ConsensusEventTypeFromJSON,
    ConsensusEventTypeToJSON,
    ConsensusTxMethod,
    ConsensusTxMethodFromJSON,
    ConsensusTxMethodToJSON,
    DebondingDelegationList,
    DebondingDelegationListFromJSON,
    DebondingDelegationListToJSON,
    DelegationList,
    DelegationListFromJSON,
    DelegationListToJSON,
    Entity,
    EntityFromJSON,
    EntityToJSON,
    EntityList,
    EntityListFromJSON,
    EntityListToJSON,
    Epoch,
    EpochFromJSON,
    EpochToJSON,
    EpochList,
    EpochListFromJSON,
    EpochListToJSON,
    EvmNft,
    EvmNftFromJSON,
    EvmNftToJSON,
    EvmNftList,
    EvmNftListFromJSON,
    EvmNftListToJSON,
    EvmToken,
    EvmTokenFromJSON,
    EvmTokenToJSON,
    EvmTokenList,
    EvmTokenListFromJSON,
    EvmTokenListToJSON,
    InlineResponse400,
    InlineResponse400FromJSON,
    InlineResponse400ToJSON,
    Layer,
    LayerFromJSON,
    LayerToJSON,
    Node,
    NodeFromJSON,
    NodeToJSON,
    NodeList,
    NodeListFromJSON,
    NodeListToJSON,
    Proposal,
    ProposalFromJSON,
    ProposalToJSON,
    ProposalList,
    ProposalListFromJSON,
    ProposalListToJSON,
    ProposalState,
    ProposalStateFromJSON,
    ProposalStateToJSON,
    ProposalVotes,
    ProposalVotesFromJSON,
    ProposalVotesToJSON,
    RoothashMessageList,
    RoothashMessageListFromJSON,
    RoothashMessageListToJSON,
    RoothashMessageType,
    RoothashMessageTypeFromJSON,
    RoothashMessageTypeToJSON,
    Runtime,
    RuntimeFromJSON,
    RuntimeToJSON,
    RuntimeAccount,
    RuntimeAccountFromJSON,
    RuntimeAccountToJSON,
    RuntimeBlockList,
    RuntimeBlockListFromJSON,
    RuntimeBlockListToJSON,
    RuntimeEventList,
    RuntimeEventListFromJSON,
    RuntimeEventListToJSON,
    RuntimeEventType,
    RuntimeEventTypeFromJSON,
    RuntimeEventTypeToJSON,
    RuntimeStatus,
    RuntimeStatusFromJSON,
    RuntimeStatusToJSON,
    RuntimeTransactionList,
    RuntimeTransactionListFromJSON,
    RuntimeTransactionListToJSON,
    Status,
    StatusFromJSON,
    StatusToJSON,
    TokenHolderList,
    TokenHolderListFromJSON,
    TokenHolderListToJSON,
    TransactionList,
    TransactionListFromJSON,
    TransactionListToJSON,
    TxVolumeList,
    TxVolumeListFromJSON,
    TxVolumeListToJSON,
    ValidatorHistory,
    ValidatorHistoryFromJSON,
    ValidatorHistoryToJSON,
    ValidatorList,
    ValidatorListFromJSON,
    ValidatorListToJSON,
} from '../models';

export interface ConsensusAccountsAddressDebondingDelegationsGetRequest {
    address: string;
    limit?: number;
    offset?: number;
}

export interface ConsensusAccountsAddressDebondingDelegationsToGetRequest {
    address: string;
    limit?: number;
    offset?: number;
}

export interface ConsensusAccountsAddressDelegationsGetRequest {
    address: string;
    limit?: number;
    offset?: number;
}

export interface ConsensusAccountsAddressDelegationsToGetRequest {
    address: string;
    limit?: number;
    offset?: number;
}

export interface ConsensusAccountsAddressGetRequest {
    address: string;
}

export interface ConsensusAccountsGetRequest {
    limit?: number;
    offset?: number;
}

export interface ConsensusBlocksGetRequest {
    limit?: number;
    offset?: number;
    from?: number;
    to?: number;
    after?: Date;
    before?: Date;
    hash?: string;
    proposedBy?: string;
}

export interface ConsensusBlocksHeightGetRequest {
    height: number;
}

export interface ConsensusEntitiesAddressGetRequest {
    address: string;
}

export interface ConsensusEntitiesAddressNodesGetRequest {
    address: string;
    limit?: number;
    offset?: number;
}

export interface ConsensusEntitiesAddressNodesNodeIdGetRequest {
    address: string;
    nodeId: string;
}

export interface ConsensusEntitiesGetRequest {
    limit?: number;
    offset?: number;
}

export interface ConsensusEpochsEpochGetRequest {
    epoch: number;
}

export interface ConsensusEpochsGetRequest {
    limit?: number;
    offset?: number;
}

export interface ConsensusEventsGetRequest {
    limit?: number;
    offset?: number;
    block?: number;
    txIndex?: number;
    txHash?: string;
    rel?: string;
    type?: ConsensusEventType;
}

export interface ConsensusProposalsGetRequest {
    limit?: number;
    offset?: number;
    submitter?: string;
    state?: ProposalState;
}

export interface ConsensusProposalsProposalIdGetRequest {
    proposalId: number;
}

export interface ConsensusProposalsProposalIdVotesGetRequest {
    proposalId: number;
    limit?: number;
    offset?: number;
}

export interface ConsensusRoothashMessagesGetRequest {
    runtime: Runtime;
    limit?: number;
    offset?: number;
    round?: number;
    type?: RoothashMessageType;
    rel?: string;
}

export interface ConsensusTransactionsGetRequest {
    limit?: number;
    offset?: number;
    block?: number;
    method?: ConsensusTxMethod;
    sender?: string;
    rel?: string;
    after?: Date;
    before?: Date;
}

export interface ConsensusTransactionsTxHashGetRequest {
    txHash: string;
}

export interface ConsensusValidatorsAddressGetRequest {
    address: string;
}

export interface ConsensusValidatorsAddressHistoryGetRequest {
    address: string;
    limit?: number;
    offset?: number;
    from?: number;
    to?: number;
}

export interface ConsensusValidatorsGetRequest {
    limit?: number;
    offset?: number;
    name?: string;
}

export interface LayerStatsActiveAccountsGetRequest {
    layer: Layer;
    limit?: number;
    offset?: number;
    windowStepSeconds?: number;
}

export interface LayerStatsTxVolumeGetRequest {
    layer: Layer;
    limit?: number;
    offset?: number;
    windowSizeSeconds?: number;
    windowStepSeconds?: number;
}

export interface RuntimeAccountsAddressGetRequest {
    runtime: Runtime;
    address: string;
}

export interface RuntimeAccountsAddressNftsGetRequest {
    runtime: Runtime;
    address: string;
    limit?: number;
    offset?: number;
    tokenAddress?: string;
}

export interface RuntimeBlocksGetRequest {
    runtime: Runtime;
    limit?: number;
    offset?: number;
    from?: number;
    to?: number;
    after?: Date;
    before?: Date;
    hash?: string;
}

export interface RuntimeEventsGetRequest {
    runtime: Runtime;
    limit?: number;
    offset?: number;
    block?: number;
    txIndex?: number;
    txHash?: string;
    type?: RuntimeEventType;
    rel?: string;
    evmLogSignature?: string;
    contractAddress?: string;
    nftId?: string;
}

export interface RuntimeEvmTokensAddressGetRequest {
    runtime: Runtime;
    address: string;
}

export interface RuntimeEvmTokensAddressHoldersGetRequest {
    runtime: Runtime;
    address: string;
    limit?: number;
    offset?: number;
}

export interface RuntimeEvmTokensAddressNftsGetRequest {
    runtime: Runtime;
    address: string;
    limit?: number;
    offset?: number;
}

export interface RuntimeEvmTokensAddressNftsIdGetRequest {
    runtime: Runtime;
    address: string;
    id: string;
}

export interface RuntimeEvmTokensGetRequest {
    runtime: Runtime;
    limit?: number;
    offset?: number;
    name?: string;
    sortBy?: RuntimeEvmTokensGetSortByEnum;
}

export interface RuntimeStatusGetRequest {
    runtime: Runtime;
}

export interface RuntimeTransactionsGetRequest {
    runtime: Runtime;
    limit?: number;
    offset?: number;
    block?: number;
    after?: Date;
    before?: Date;
    rel?: string;
    method?: string;
}

export interface RuntimeTransactionsTxHashGetRequest {
    runtime: Runtime;
    txHash: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Returns an account\'s debonding delegations.
     */
    async consensusAccountsAddressDebondingDelegationsGetRaw(requestParameters: ConsensusAccountsAddressDebondingDelegationsGetRequest): Promise<runtime.ApiResponse<DebondingDelegationList>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusAccountsAddressDebondingDelegationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/accounts/{address}/debonding_delegations`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DebondingDelegationListFromJSON(jsonValue));
    }

    /**
     * Returns an account\'s debonding delegations.
     */
    async consensusAccountsAddressDebondingDelegationsGet(requestParameters: ConsensusAccountsAddressDebondingDelegationsGetRequest): Promise<DebondingDelegationList> {
        const response = await this.consensusAccountsAddressDebondingDelegationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of debonding delegations to an account.
     */
    async consensusAccountsAddressDebondingDelegationsToGetRaw(requestParameters: ConsensusAccountsAddressDebondingDelegationsToGetRequest): Promise<runtime.ApiResponse<DebondingDelegationList>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusAccountsAddressDebondingDelegationsToGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/accounts/{address}/debonding_delegations_to`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DebondingDelegationListFromJSON(jsonValue));
    }

    /**
     * Returns a list of debonding delegations to an account.
     */
    async consensusAccountsAddressDebondingDelegationsToGet(requestParameters: ConsensusAccountsAddressDebondingDelegationsToGetRequest): Promise<DebondingDelegationList> {
        const response = await this.consensusAccountsAddressDebondingDelegationsToGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns an account\'s delegations.
     */
    async consensusAccountsAddressDelegationsGetRaw(requestParameters: ConsensusAccountsAddressDelegationsGetRequest): Promise<runtime.ApiResponse<DelegationList>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusAccountsAddressDelegationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/accounts/{address}/delegations`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DelegationListFromJSON(jsonValue));
    }

    /**
     * Returns an account\'s delegations.
     */
    async consensusAccountsAddressDelegationsGet(requestParameters: ConsensusAccountsAddressDelegationsGetRequest): Promise<DelegationList> {
        const response = await this.consensusAccountsAddressDelegationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of delegations to an account.
     */
    async consensusAccountsAddressDelegationsToGetRaw(requestParameters: ConsensusAccountsAddressDelegationsToGetRequest): Promise<runtime.ApiResponse<DelegationList>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusAccountsAddressDelegationsToGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/accounts/{address}/delegations_to`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DelegationListFromJSON(jsonValue));
    }

    /**
     * Returns a list of delegations to an account.
     */
    async consensusAccountsAddressDelegationsToGet(requestParameters: ConsensusAccountsAddressDelegationsToGetRequest): Promise<DelegationList> {
        const response = await this.consensusAccountsAddressDelegationsToGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a consensus layer account.
     */
    async consensusAccountsAddressGetRaw(requestParameters: ConsensusAccountsAddressGetRequest): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusAccountsAddressGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/accounts/{address}`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * Returns a consensus layer account.
     */
    async consensusAccountsAddressGet(requestParameters: ConsensusAccountsAddressGetRequest): Promise<Account> {
        const response = await this.consensusAccountsAddressGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of consensus layer accounts. Note that for performance reasons, the info returned by this endpoint may be slightly stale (<2 minutes). For the most up-to-date account state, query the single-account endpoint. 
     */
    async consensusAccountsGetRaw(requestParameters: ConsensusAccountsGetRequest): Promise<runtime.ApiResponse<AccountList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountListFromJSON(jsonValue));
    }

    /**
     * Returns a list of consensus layer accounts. Note that for performance reasons, the info returned by this endpoint may be slightly stale (<2 minutes). For the most up-to-date account state, query the single-account endpoint. 
     */
    async consensusAccountsGet(requestParameters: ConsensusAccountsGetRequest): Promise<AccountList> {
        const response = await this.consensusAccountsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of consensus blocks, sorted from most to least recent.
     */
    async consensusBlocksGetRaw(requestParameters: ConsensusBlocksGetRequest): Promise<runtime.ApiResponse<BlockList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = (requestParameters.after as any).toISOString();
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = (requestParameters.before as any).toISOString();
        }

        if (requestParameters.hash !== undefined) {
            queryParameters['hash'] = requestParameters.hash;
        }

        if (requestParameters.proposedBy !== undefined) {
            queryParameters['proposed_by'] = requestParameters.proposedBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/blocks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockListFromJSON(jsonValue));
    }

    /**
     * Returns a list of consensus blocks, sorted from most to least recent.
     */
    async consensusBlocksGet(requestParameters: ConsensusBlocksGetRequest): Promise<BlockList> {
        const response = await this.consensusBlocksGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a consensus block.
     */
    async consensusBlocksHeightGetRaw(requestParameters: ConsensusBlocksHeightGetRequest): Promise<runtime.ApiResponse<Block>> {
        if (requestParameters.height === null || requestParameters.height === undefined) {
            throw new runtime.RequiredError('height','Required parameter requestParameters.height was null or undefined when calling consensusBlocksHeightGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/blocks/{height}`.replace(`{${"height"}}`, encodeURIComponent(String(requestParameters.height))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockFromJSON(jsonValue));
    }

    /**
     * Returns a consensus block.
     */
    async consensusBlocksHeightGet(requestParameters: ConsensusBlocksHeightGetRequest): Promise<Block> {
        const response = await this.consensusBlocksHeightGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns an entity registered at the consensus layer.
     */
    async consensusEntitiesAddressGetRaw(requestParameters: ConsensusEntitiesAddressGetRequest): Promise<runtime.ApiResponse<Entity>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusEntitiesAddressGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/entities/{address}`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityFromJSON(jsonValue));
    }

    /**
     * Returns an entity registered at the consensus layer.
     */
    async consensusEntitiesAddressGet(requestParameters: ConsensusEntitiesAddressGetRequest): Promise<Entity> {
        const response = await this.consensusEntitiesAddressGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of nodes registered at the consensus layer.
     */
    async consensusEntitiesAddressNodesGetRaw(requestParameters: ConsensusEntitiesAddressNodesGetRequest): Promise<runtime.ApiResponse<NodeList>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusEntitiesAddressNodesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/entities/{address}/nodes`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NodeListFromJSON(jsonValue));
    }

    /**
     * Returns a list of nodes registered at the consensus layer.
     */
    async consensusEntitiesAddressNodesGet(requestParameters: ConsensusEntitiesAddressNodesGetRequest): Promise<NodeList> {
        const response = await this.consensusEntitiesAddressNodesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a node registered at the consensus layer.
     */
    async consensusEntitiesAddressNodesNodeIdGetRaw(requestParameters: ConsensusEntitiesAddressNodesNodeIdGetRequest): Promise<runtime.ApiResponse<Node>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusEntitiesAddressNodesNodeIdGet.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling consensusEntitiesAddressNodesNodeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/entities/{address}/nodes/{node_id}`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))).replace(`{${"node_id"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NodeFromJSON(jsonValue));
    }

    /**
     * Returns a node registered at the consensus layer.
     */
    async consensusEntitiesAddressNodesNodeIdGet(requestParameters: ConsensusEntitiesAddressNodesNodeIdGetRequest): Promise<Node> {
        const response = await this.consensusEntitiesAddressNodesNodeIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of entities registered at the consensus layer.
     */
    async consensusEntitiesGetRaw(requestParameters: ConsensusEntitiesGetRequest): Promise<runtime.ApiResponse<EntityList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/entities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityListFromJSON(jsonValue));
    }

    /**
     * Returns a list of entities registered at the consensus layer.
     */
    async consensusEntitiesGet(requestParameters: ConsensusEntitiesGetRequest): Promise<EntityList> {
        const response = await this.consensusEntitiesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a consensus epoch.
     */
    async consensusEpochsEpochGetRaw(requestParameters: ConsensusEpochsEpochGetRequest): Promise<runtime.ApiResponse<Epoch>> {
        if (requestParameters.epoch === null || requestParameters.epoch === undefined) {
            throw new runtime.RequiredError('epoch','Required parameter requestParameters.epoch was null or undefined when calling consensusEpochsEpochGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/epochs/{epoch}`.replace(`{${"epoch"}}`, encodeURIComponent(String(requestParameters.epoch))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EpochFromJSON(jsonValue));
    }

    /**
     * Returns a consensus epoch.
     */
    async consensusEpochsEpochGet(requestParameters: ConsensusEpochsEpochGetRequest): Promise<Epoch> {
        const response = await this.consensusEpochsEpochGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of consensus epochs.
     */
    async consensusEpochsGetRaw(requestParameters: ConsensusEpochsGetRequest): Promise<runtime.ApiResponse<EpochList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/epochs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EpochListFromJSON(jsonValue));
    }

    /**
     * Returns a list of consensus epochs.
     */
    async consensusEpochsGet(requestParameters: ConsensusEpochsGetRequest): Promise<EpochList> {
        const response = await this.consensusEpochsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of consensus events.
     */
    async consensusEventsGetRaw(requestParameters: ConsensusEventsGetRequest): Promise<runtime.ApiResponse<ConsensusEventList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.block !== undefined) {
            queryParameters['block'] = requestParameters.block;
        }

        if (requestParameters.txIndex !== undefined) {
            queryParameters['tx_index'] = requestParameters.txIndex;
        }

        if (requestParameters.txHash !== undefined) {
            queryParameters['tx_hash'] = requestParameters.txHash;
        }

        if (requestParameters.rel !== undefined) {
            queryParameters['rel'] = requestParameters.rel;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsensusEventListFromJSON(jsonValue));
    }

    /**
     * Returns a list of consensus events.
     */
    async consensusEventsGet(requestParameters: ConsensusEventsGetRequest): Promise<ConsensusEventList> {
        const response = await this.consensusEventsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of governance proposals.
     */
    async consensusProposalsGetRaw(requestParameters: ConsensusProposalsGetRequest): Promise<runtime.ApiResponse<ProposalList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.submitter !== undefined) {
            queryParameters['submitter'] = requestParameters.submitter;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/proposals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalListFromJSON(jsonValue));
    }

    /**
     * Returns a list of governance proposals.
     */
    async consensusProposalsGet(requestParameters: ConsensusProposalsGetRequest): Promise<ProposalList> {
        const response = await this.consensusProposalsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a governance proposal.
     */
    async consensusProposalsProposalIdGetRaw(requestParameters: ConsensusProposalsProposalIdGetRequest): Promise<runtime.ApiResponse<Proposal>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling consensusProposalsProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/proposals/{proposal_id}`.replace(`{${"proposal_id"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalFromJSON(jsonValue));
    }

    /**
     * Returns a governance proposal.
     */
    async consensusProposalsProposalIdGet(requestParameters: ConsensusProposalsProposalIdGetRequest): Promise<Proposal> {
        const response = await this.consensusProposalsProposalIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of votes for a governance proposal.
     */
    async consensusProposalsProposalIdVotesGetRaw(requestParameters: ConsensusProposalsProposalIdVotesGetRequest): Promise<runtime.ApiResponse<ProposalVotes>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling consensusProposalsProposalIdVotesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/proposals/{proposal_id}/votes`.replace(`{${"proposal_id"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalVotesFromJSON(jsonValue));
    }

    /**
     * Returns a list of votes for a governance proposal.
     */
    async consensusProposalsProposalIdVotesGet(requestParameters: ConsensusProposalsProposalIdVotesGetRequest): Promise<ProposalVotes> {
        const response = await this.consensusProposalsProposalIdVotesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async consensusRoothashMessagesGetRaw(requestParameters: ConsensusRoothashMessagesGetRequest): Promise<runtime.ApiResponse<RoothashMessageList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling consensusRoothashMessagesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.runtime !== undefined) {
            queryParameters['runtime'] = requestParameters.runtime;
        }

        if (requestParameters.round !== undefined) {
            queryParameters['round'] = requestParameters.round;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.rel !== undefined) {
            queryParameters['rel'] = requestParameters.rel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/roothash_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoothashMessageListFromJSON(jsonValue));
    }

    /**
     */
    async consensusRoothashMessagesGet(requestParameters: ConsensusRoothashMessagesGetRequest): Promise<RoothashMessageList> {
        const response = await this.consensusRoothashMessagesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of consensus transactions.
     */
    async consensusTransactionsGetRaw(requestParameters: ConsensusTransactionsGetRequest): Promise<runtime.ApiResponse<TransactionList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.block !== undefined) {
            queryParameters['block'] = requestParameters.block;
        }

        if (requestParameters.method !== undefined) {
            queryParameters['method'] = requestParameters.method;
        }

        if (requestParameters.sender !== undefined) {
            queryParameters['sender'] = requestParameters.sender;
        }

        if (requestParameters.rel !== undefined) {
            queryParameters['rel'] = requestParameters.rel;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = (requestParameters.after as any).toISOString();
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = (requestParameters.before as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionListFromJSON(jsonValue));
    }

    /**
     * Returns a list of consensus transactions.
     */
    async consensusTransactionsGet(requestParameters: ConsensusTransactionsGetRequest): Promise<TransactionList> {
        const response = await this.consensusTransactionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns consensus transactions with the given transaction hash.
     */
    async consensusTransactionsTxHashGetRaw(requestParameters: ConsensusTransactionsTxHashGetRequest): Promise<runtime.ApiResponse<TransactionList>> {
        if (requestParameters.txHash === null || requestParameters.txHash === undefined) {
            throw new runtime.RequiredError('txHash','Required parameter requestParameters.txHash was null or undefined when calling consensusTransactionsTxHashGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/transactions/{tx_hash}`.replace(`{${"tx_hash"}}`, encodeURIComponent(String(requestParameters.txHash))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionListFromJSON(jsonValue));
    }

    /**
     * Returns consensus transactions with the given transaction hash.
     */
    async consensusTransactionsTxHashGet(requestParameters: ConsensusTransactionsTxHashGetRequest): Promise<TransactionList> {
        const response = await this.consensusTransactionsTxHashGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a validator registered at the consensus layer.
     */
    async consensusValidatorsAddressGetRaw(requestParameters: ConsensusValidatorsAddressGetRequest): Promise<runtime.ApiResponse<ValidatorList>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusValidatorsAddressGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/validators/{address}`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidatorListFromJSON(jsonValue));
    }

    /**
     * Returns a validator registered at the consensus layer.
     */
    async consensusValidatorsAddressGet(requestParameters: ConsensusValidatorsAddressGetRequest): Promise<ValidatorList> {
        const response = await this.consensusValidatorsAddressGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns historical information for a single validator.
     */
    async consensusValidatorsAddressHistoryGetRaw(requestParameters: ConsensusValidatorsAddressHistoryGetRequest): Promise<runtime.ApiResponse<ValidatorHistory>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling consensusValidatorsAddressHistoryGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/validators/{address}/history`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidatorHistoryFromJSON(jsonValue));
    }

    /**
     * Returns historical information for a single validator.
     */
    async consensusValidatorsAddressHistoryGet(requestParameters: ConsensusValidatorsAddressHistoryGetRequest): Promise<ValidatorHistory> {
        const response = await this.consensusValidatorsAddressHistoryGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of validators registered at the consensus layer (the list includes all registered entities, even those without a currently active validator node).
     */
    async consensusValidatorsGetRaw(requestParameters: ConsensusValidatorsGetRequest): Promise<runtime.ApiResponse<ValidatorList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consensus/validators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidatorListFromJSON(jsonValue));
    }

    /**
     * Returns a list of validators registered at the consensus layer (the list includes all registered entities, even those without a currently active validator node).
     */
    async consensusValidatorsGet(requestParameters: ConsensusValidatorsGetRequest): Promise<ValidatorList> {
        const response = await this.consensusValidatorsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the status of indexing.
     */
    async getStatusRaw(): Promise<runtime.ApiResponse<Status>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Returns the status of indexing.
     */
    async getStatus(): Promise<Status> {
        const response = await this.getStatusRaw();
        return await response.value();
    }

    /**
     * Returns a (sliding) timeline of the recorded daily unique active accounts for either consensus or one of the paratimes. 
     */
    async layerStatsActiveAccountsGetRaw(requestParameters: LayerStatsActiveAccountsGetRequest): Promise<runtime.ApiResponse<ActiveAccountsList>> {
        if (requestParameters.layer === null || requestParameters.layer === undefined) {
            throw new runtime.RequiredError('layer','Required parameter requestParameters.layer was null or undefined when calling layerStatsActiveAccountsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.windowStepSeconds !== undefined) {
            queryParameters['window_step_seconds'] = requestParameters.windowStepSeconds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{layer}/stats/active_accounts`.replace(`{${"layer"}}`, encodeURIComponent(String(requestParameters.layer))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveAccountsListFromJSON(jsonValue));
    }

    /**
     * Returns a (sliding) timeline of the recorded daily unique active accounts for either consensus or one of the paratimes. 
     */
    async layerStatsActiveAccountsGet(requestParameters: LayerStatsActiveAccountsGetRequest): Promise<ActiveAccountsList> {
        const response = await this.layerStatsActiveAccountsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a timeline of the transaction volume at the chosen granularity, for either consensus or one of the paratimes. 
     */
    async layerStatsTxVolumeGetRaw(requestParameters: LayerStatsTxVolumeGetRequest): Promise<runtime.ApiResponse<TxVolumeList>> {
        if (requestParameters.layer === null || requestParameters.layer === undefined) {
            throw new runtime.RequiredError('layer','Required parameter requestParameters.layer was null or undefined when calling layerStatsTxVolumeGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.windowSizeSeconds !== undefined) {
            queryParameters['window_size_seconds'] = requestParameters.windowSizeSeconds;
        }

        if (requestParameters.windowStepSeconds !== undefined) {
            queryParameters['window_step_seconds'] = requestParameters.windowStepSeconds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{layer}/stats/tx_volume`.replace(`{${"layer"}}`, encodeURIComponent(String(requestParameters.layer))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TxVolumeListFromJSON(jsonValue));
    }

    /**
     * Returns a timeline of the transaction volume at the chosen granularity, for either consensus or one of the paratimes. 
     */
    async layerStatsTxVolumeGet(requestParameters: LayerStatsTxVolumeGetRequest): Promise<TxVolumeList> {
        const response = await this.layerStatsTxVolumeGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a runtime account.
     */
    async runtimeAccountsAddressGetRaw(requestParameters: RuntimeAccountsAddressGetRequest): Promise<runtime.ApiResponse<RuntimeAccount>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeAccountsAddressGet.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling runtimeAccountsAddressGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/accounts/{address}`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))).replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeAccountFromJSON(jsonValue));
    }

    /**
     * Returns a runtime account.
     */
    async runtimeAccountsAddressGet(requestParameters: RuntimeAccountsAddressGetRequest): Promise<RuntimeAccount> {
        const response = await this.runtimeAccountsAddressGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of non-fungible token (NFT) instances owned by an account. 
     */
    async runtimeAccountsAddressNftsGetRaw(requestParameters: RuntimeAccountsAddressNftsGetRequest): Promise<runtime.ApiResponse<EvmNftList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeAccountsAddressNftsGet.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling runtimeAccountsAddressNftsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.tokenAddress !== undefined) {
            queryParameters['token_address'] = requestParameters.tokenAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/accounts/{address}/nfts`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))).replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EvmNftListFromJSON(jsonValue));
    }

    /**
     * Returns the list of non-fungible token (NFT) instances owned by an account. 
     */
    async runtimeAccountsAddressNftsGet(requestParameters: RuntimeAccountsAddressNftsGetRequest): Promise<EvmNftList> {
        const response = await this.runtimeAccountsAddressNftsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of Runtime blocks.
     */
    async runtimeBlocksGetRaw(requestParameters: RuntimeBlocksGetRequest): Promise<runtime.ApiResponse<RuntimeBlockList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeBlocksGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = (requestParameters.after as any).toISOString();
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = (requestParameters.before as any).toISOString();
        }

        if (requestParameters.hash !== undefined) {
            queryParameters['hash'] = requestParameters.hash;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/blocks`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeBlockListFromJSON(jsonValue));
    }

    /**
     * Returns a list of Runtime blocks.
     */
    async runtimeBlocksGet(requestParameters: RuntimeBlocksGetRequest): Promise<RuntimeBlockList> {
        const response = await this.runtimeBlocksGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of runtime events.
     */
    async runtimeEventsGetRaw(requestParameters: RuntimeEventsGetRequest): Promise<runtime.ApiResponse<RuntimeEventList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeEventsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.block !== undefined) {
            queryParameters['block'] = requestParameters.block;
        }

        if (requestParameters.txIndex !== undefined) {
            queryParameters['tx_index'] = requestParameters.txIndex;
        }

        if (requestParameters.txHash !== undefined) {
            queryParameters['tx_hash'] = requestParameters.txHash;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.rel !== undefined) {
            queryParameters['rel'] = requestParameters.rel;
        }

        if (requestParameters.evmLogSignature !== undefined) {
            queryParameters['evm_log_signature'] = requestParameters.evmLogSignature;
        }

        if (requestParameters.contractAddress !== undefined) {
            queryParameters['contract_address'] = requestParameters.contractAddress;
        }

        if (requestParameters.nftId !== undefined) {
            queryParameters['nft_id'] = requestParameters.nftId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/events`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeEventListFromJSON(jsonValue));
    }

    /**
     * Returns a list of runtime events.
     */
    async runtimeEventsGet(requestParameters: RuntimeEventsGetRequest): Promise<RuntimeEventList> {
        const response = await this.runtimeEventsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info on an EVM (ERC-20, ...) token on the runtime.
     */
    async runtimeEvmTokensAddressGetRaw(requestParameters: RuntimeEvmTokensAddressGetRequest): Promise<runtime.ApiResponse<EvmToken>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeEvmTokensAddressGet.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling runtimeEvmTokensAddressGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/evm_tokens/{address}`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))).replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EvmTokenFromJSON(jsonValue));
    }

    /**
     * Returns info on an EVM (ERC-20, ...) token on the runtime.
     */
    async runtimeEvmTokensAddressGet(requestParameters: RuntimeEvmTokensAddressGetRequest): Promise<EvmToken> {
        const response = await this.runtimeEvmTokensAddressGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of holders of an EVM (ERC-20, ...) token. This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list. 
     */
    async runtimeEvmTokensAddressHoldersGetRaw(requestParameters: RuntimeEvmTokensAddressHoldersGetRequest): Promise<runtime.ApiResponse<TokenHolderList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeEvmTokensAddressHoldersGet.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling runtimeEvmTokensAddressHoldersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/evm_tokens/{address}/holders`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))).replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenHolderListFromJSON(jsonValue));
    }

    /**
     * Returns the list of holders of an EVM (ERC-20, ...) token. This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list. 
     */
    async runtimeEvmTokensAddressHoldersGet(requestParameters: RuntimeEvmTokensAddressHoldersGetRequest): Promise<TokenHolderList> {
        const response = await this.runtimeEvmTokensAddressHoldersGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of non-fungible token (NFT) instances of an EVM (ERC-721, ...) token. This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list. 
     */
    async runtimeEvmTokensAddressNftsGetRaw(requestParameters: RuntimeEvmTokensAddressNftsGetRequest): Promise<runtime.ApiResponse<EvmNftList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeEvmTokensAddressNftsGet.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling runtimeEvmTokensAddressNftsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/evm_tokens/{address}/nfts`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))).replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EvmNftListFromJSON(jsonValue));
    }

    /**
     * Returns the list of non-fungible token (NFT) instances of an EVM (ERC-721, ...) token. This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list. 
     */
    async runtimeEvmTokensAddressNftsGet(requestParameters: RuntimeEvmTokensAddressNftsGetRequest): Promise<EvmNftList> {
        const response = await this.runtimeEvmTokensAddressNftsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the non-fungible token (NFT) instance of an EVM (ERC-721, ...) token. 
     */
    async runtimeEvmTokensAddressNftsIdGetRaw(requestParameters: RuntimeEvmTokensAddressNftsIdGetRequest): Promise<runtime.ApiResponse<EvmNft>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeEvmTokensAddressNftsIdGet.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling runtimeEvmTokensAddressNftsIdGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling runtimeEvmTokensAddressNftsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/evm_tokens/{address}/nfts/{id}`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))).replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EvmNftFromJSON(jsonValue));
    }

    /**
     * Returns the non-fungible token (NFT) instance of an EVM (ERC-721, ...) token. 
     */
    async runtimeEvmTokensAddressNftsIdGet(requestParameters: RuntimeEvmTokensAddressNftsIdGetRequest): Promise<EvmNft> {
        const response = await this.runtimeEvmTokensAddressNftsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of EVM (ERC-20, ...) tokens on the runtime.
     */
    async runtimeEvmTokensGetRaw(requestParameters: RuntimeEvmTokensGetRequest): Promise<runtime.ApiResponse<EvmTokenList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeEvmTokensGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/evm_tokens`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EvmTokenListFromJSON(jsonValue));
    }

    /**
     * Returns a list of EVM (ERC-20, ...) tokens on the runtime.
     */
    async runtimeEvmTokensGet(requestParameters: RuntimeEvmTokensGetRequest): Promise<EvmTokenList> {
        const response = await this.runtimeEvmTokensGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the runtime status.
     */
    async runtimeStatusGetRaw(requestParameters: RuntimeStatusGetRequest): Promise<runtime.ApiResponse<RuntimeStatus>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeStatusGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/status`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeStatusFromJSON(jsonValue));
    }

    /**
     * Returns the runtime status.
     */
    async runtimeStatusGet(requestParameters: RuntimeStatusGetRequest): Promise<RuntimeStatus> {
        const response = await this.runtimeStatusGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of Runtime transactions.
     */
    async runtimeTransactionsGetRaw(requestParameters: RuntimeTransactionsGetRequest): Promise<runtime.ApiResponse<RuntimeTransactionList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeTransactionsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.block !== undefined) {
            queryParameters['block'] = requestParameters.block;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = (requestParameters.after as any).toISOString();
        }

        if (requestParameters.before !== undefined) {
            queryParameters['before'] = (requestParameters.before as any).toISOString();
        }

        if (requestParameters.rel !== undefined) {
            queryParameters['rel'] = requestParameters.rel;
        }

        if (requestParameters.method !== undefined) {
            queryParameters['method'] = requestParameters.method;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/transactions`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeTransactionListFromJSON(jsonValue));
    }

    /**
     * Returns a list of Runtime transactions.
     */
    async runtimeTransactionsGet(requestParameters: RuntimeTransactionsGetRequest): Promise<RuntimeTransactionList> {
        const response = await this.runtimeTransactionsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns runtime transactions with the given transaction hash.
     */
    async runtimeTransactionsTxHashGetRaw(requestParameters: RuntimeTransactionsTxHashGetRequest): Promise<runtime.ApiResponse<RuntimeTransactionList>> {
        if (requestParameters.runtime === null || requestParameters.runtime === undefined) {
            throw new runtime.RequiredError('runtime','Required parameter requestParameters.runtime was null or undefined when calling runtimeTransactionsTxHashGet.');
        }

        if (requestParameters.txHash === null || requestParameters.txHash === undefined) {
            throw new runtime.RequiredError('txHash','Required parameter requestParameters.txHash was null or undefined when calling runtimeTransactionsTxHashGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{runtime}/transactions/{tx_hash}`.replace(`{${"runtime"}}`, encodeURIComponent(String(requestParameters.runtime))).replace(`{${"tx_hash"}}`, encodeURIComponent(String(requestParameters.txHash))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeTransactionListFromJSON(jsonValue));
    }

    /**
     * Returns runtime transactions with the given transaction hash.
     */
    async runtimeTransactionsTxHashGet(requestParameters: RuntimeTransactionsTxHashGetRequest): Promise<RuntimeTransactionList> {
        const response = await this.runtimeTransactionsTxHashGetRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum RuntimeEvmTokensGetSortByEnum {
    TotalHolders = 'total_holders',
    MarketCap = 'market_cap'
}
