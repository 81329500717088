/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoothashMessageType,
    RoothashMessageTypeFromJSON,
    RoothashMessageTypeFromJSONTyped,
    RoothashMessageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoothashMessage
 */
export interface RoothashMessage {
    /**
     * The runtime that sent this message.
     * @type {string}
     * @memberof RoothashMessage
     */
    runtime: string;
    /**
     * The block round when the runtime sent this message.
     * @type {number}
     * @memberof RoothashMessage
     */
    round: number;
    /**
     * The 0-based index of this message in the block.
     * @type {number}
     * @memberof RoothashMessage
     */
    index: number;
    /**
     * The type of this message.
     * @type {RoothashMessageType}
     * @memberof RoothashMessage
     */
    type?: RoothashMessageType;
    /**
     * The "body" of a message is a structure within the
     * `github.com/oasisprotocol/oasis-core/go/roothash/api/message`
     * `Message` structure
     * (https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/roothash/api/message#Message).
     * For example, if the type is `staking.withdraw`, the body is the Go
     * `Message` structure's `.Staking.Withdraw` field, which is a
     * `github.com/oasisprotocol/oasis-core/go/staking/api` `Withdraw`
     * structure
     * (https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/staking/api#Withdraw),
     * with `from` and `amount` fields in JSON.
     * @type {object}
     * @memberof RoothashMessage
     */
    body?: object;
    /**
     * If executing this message resulted in an error, this is the
     * error's module.
     * @type {string}
     * @memberof RoothashMessage
     */
    error_module?: string;
    /**
     * If executing this message resulted in an error, this is the
     * error's code.
     * @type {number}
     * @memberof RoothashMessage
     */
    error_code?: number;
    /**
     * A result value that consensus provided after executing this
     * message. These aren't centrally registered anywhere, so look at
     * the consensus apps' `ExecuteMessage`
     * (https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/cometbft/api#MessageSubscriber)
     * implementations to see what they return. For example, a
     * `staking.withdraw` type message gives a
     * `github.com/oasisprotocol/oasis-core/go/staking/api`
     * `WithdrawResult` structure as its result
     * (`https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/staking/api#WithdrawResult`)
     * with `owner`, `beneficiary`, `allowance`, and `amount_change`
     * fields.
     * @type {any}
     * @memberof RoothashMessage
     */
    result?: any | null;
}

export function RoothashMessageFromJSON(json: any): RoothashMessage {
    return RoothashMessageFromJSONTyped(json, false);
}

export function RoothashMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoothashMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'runtime': json['runtime'],
        'round': json['round'],
        'index': json['index'],
        'type': !exists(json, 'type') ? undefined : RoothashMessageTypeFromJSON(json['type']),
        'body': !exists(json, 'body') ? undefined : json['body'],
        'error_module': !exists(json, 'error_module') ? undefined : json['error_module'],
        'error_code': !exists(json, 'error_code') ? undefined : json['error_code'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function RoothashMessageToJSON(value?: RoothashMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'runtime': value.runtime,
        'round': value.round,
        'index': value.index,
        'type': RoothashMessageTypeToJSON(value.type),
        'body': value.body,
        'error_module': value.error_module,
        'error_code': value.error_code,
        'result': value.result,
    };
}


